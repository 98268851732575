import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../context';
import Sidebar from '../sideBar';
import styles from './overview.module.css';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const PersonalInfoCard = ({ userData }) => {
  const [referralLink, setReferralLink] = useState('');

  if (!userData) {
    return <div className={styles.personalInfoCard}>User details available.</div>;
  }

  const getInitials = (firstName, lastName) => {
    if (!firstName && !lastName) return '';
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('/profile-information');
  };

  const getTier = (personaStatus) => {
    return personaStatus === 'approved' ? 2 : 1;
  };

  if (referralLink === '' && userData?.referralCode) {
    const currentUrl = window.location.host;
    const referralUrl = `https://${currentUrl}/invite/ref/${userData?.referralCode}`;
    setReferralLink(referralUrl);
  }

  const copyReferralLink = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
    }
  };

  const shareOnWhatsApp = () => {
    if (referralLink) {
      const message = `Hey, I use Lipaworld to send more than money home. Really easy and affordable! Give it a try using this link: ${referralLink}. Thanks ${userData?.firstName}!`;
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  return (
    <div className={styles.personalInfoCard}>
      <div className={styles.cardHeader}>
        <h2>Personal Information</h2>
        <button className={styles.editButton} onClick={handleEdit}>
          Edit
        </button>
      </div>

      <div className={styles.userProfile}>
        <div className={styles.avatar}>{getInitials(userData.firstName, userData.lastName)}</div>
        <div className={styles.userInfo}>
          <h3>{`${userData.firstName || ''} ${userData.lastName || ''}`}</h3>
          <span className={styles.tierBadge}>Tier {getTier(userData.personaStatus)}</span>
        </div>
      </div>

      <div className={styles.contactDetails}>
        <div className={styles.fieldGroup}>
          <label>Email Address</label>
          <div className={styles.field}>
            <span className={styles.fieldValue}>{userData.email || 'No email provided'}</span>
            <span className={styles.verifiedBadge}>Verified</span>
          </div>
        </div>

        <div className={styles.fieldGroup}>
          <label>Phone number</label>
          <div className={styles.field}>
            <span className={styles.fieldValue}>{userData.phoneNumber || 'No phone provided'}</span>
            <span className={styles.unverifiedBadge}>Unverified</span>
          </div>
        </div>

        {userData.referralCode && (
          <div className={styles.fieldGroup}>
            <label>Referral Code</label>
            <div className={styles.field}>
              <span className={styles.fieldValue}>{userData.referralCode || 'No referral code available'}</span>
              <span className={styles.verifiedBadge} onClick={shareOnWhatsApp} role="button" aria-label="Share link">
                Share link on WhatsApp
              </span>
              <span className={styles.verifiedBadge} onClick={copyReferralLink} role="button" aria-label="Copy link">
                Copy link
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const VoucherManagementCard = () => {
  const { authState } = useAuth();

  const { data: voucherCount = 0, isLoading: loading } = useQuery({
    queryKey: ['vouchers', authState?.id],
    queryFn: async () => {
      if (!authState?.id) return 0;
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_USERS_V1_URL}/purchase-history?userId=${authState.id}`,
        timeout: 30000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return response.data.length;
    },
    enabled: !!authState?.id,
  });

  return (
    <div className={styles.voucherCard}>
      <h2>Voucher Management</h2>
      <Link to="/voucher-management">
        <div className={styles.voucherStats}>
          <div className={styles.statCard}>
            <h3>{loading ? 'Loading...' : voucherCount}</h3>
            <div className={styles.statLabel}>
              Purchased <span className={styles.arrow}>→</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const RecipientCard = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  const { data: recipients = [], isLoading: loading } = useQuery({
    queryKey: ['recipients', authState?.id],
    queryFn: async () => {
      const token = localStorage.getItem('token');
      if (!token || !authState?.id) return [];

      const response = await axios.get(
        `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipients?userId=${authState.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return Array.isArray(response.data.data.recipients)
        ? response.data.recipients.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)).slice(0, 3)
        : [];
    },
    enabled: !!authState?.id,
  });

  const handleSeeAll = () => {
    navigate('/recipient-management');
  };

  if (loading) {
    return (
      <div className={styles.recipientCard}>
        <div className={styles.cardHeader}>
          <h2>Recipient Management</h2>
        </div>
        <div>Loading recipients...</div>
      </div>
    );
  }

  const recipientsList = Array.isArray(recipients) ? recipients : [];

  return (
    <div className={styles.recipientCard}>
      <div className={styles.cardHeader}>
        <h2>Recipient Management</h2>
        <button onClick={handleSeeAll} className={styles.seeAll}>
          See all
        </button>
      </div>

      <div className={styles.recipientList}>
        {recipientsList.map((recipient) => (
          <div key={recipient.id} className={styles.recipient}>
            <div className={styles.avatarrecipient} style={{ background: '#E9D7FE' }}>
              <span>{`${recipient.name?.[0] || ''}${recipient.surname?.[0] || ''}`}</span>
            </div>
            <span className={styles.name}>{`${recipient.name} ${recipient.surname}`}</span>
          </div>
        ))}

        {recipientsList.length === 0 && <div className={styles.noRecipients}>No recipients found</div>}
      </div>
    </div>
  );
};

const SettingsCard = () => (
  <div className={styles.securityCard}>
    <h2>Security Settings</h2>
    <div className={styles.settingsList}>
      <Link to="/security-settings" className={styles.settingsItem}>
        <div className={styles.settingsContent}>
          <h3>KYC Verification</h3>
          <p>Check your KYC status</p>
        </div>
        <span className={styles.arrow}>›</span>
      </Link>

      {/* <Link to="/security-settings" className={styles.settingsItem}>
        <div className={styles.settingsContent}>
          <h3>Password Settings</h3>
          <p>Option to change password.</p>
        </div>
        <span className={styles.arrow}>›</span>
      </Link> */}
    </div>
  </div>
);

const ContactCard = () => (
  <div className={styles.contactCard}>
    <h2>Contact Us</h2>

    <div className={styles.contactList}>
      <Link to="/contact" className={styles.contactItem}>
        <div className={styles.contactInfo}>
          <h3>Contact Support</h3>
          <p>For general inquiries, partnership opportunities or customer support.</p>
        </div>
        <span className={styles.arrow}>›</span>
      </Link>

      <Link to="/contact?selected-tab=faqs" className={styles.contactItem}>
        <div className={styles.contactInfo}>
          <h3>FAQs & Resources</h3>
          <p>Access to help resources for common account queries.</p>
        </div>
        <span className={styles.arrow}>›</span>
      </Link>
    </div>
  </div>
);

const AccountOverview = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate('/signin');
    }
  }, [authState.isAuthenticated, navigate]);

  if (!authState || !authState.isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Account Overview</h1>
        <div className={styles.grid}>
          <PersonalInfoCard userData={authState} />
          <SettingsCard />
          <VoucherManagementCard />
          <RecipientCard />
          <ContactCard />
        </div>
      </main>
    </div>
  );
};

export default AccountOverview;

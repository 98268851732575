import React from 'react';
import { Link } from 'react-router-dom';

const Fostering = () => {
  return (
    <div className="fostering-container">
      <div className="image-section" data-aos="fade-down">
        <img
          src="/img/blog/ZimCross.png"
          alt="cooperate gifting"
          className="community-image"
        />
      </div>
      <div className="content-section" data-aos="fade-right">
        <div className="category-and-title">
          <div className="metadata">
            <span className="category">Article</span>
            <span className="separator">•</span>
            <span className="read-time">15min read</span>
            <span className="separator">•</span>
            <span className="date">February 10, 2025</span>
          </div>
          <h2 className="title">Supporting Loved Ones in Zimbabwe: Shop Groceries Seamlessly with Crossroads and Lipaworld
          </h2>
        </div>
        <p className="description">
        In today’s interconnected world, supporting loved ones across borders has...
        </p>
        <div className="author-section">
          {/* <img
            src="/img/blog/rev1.png"
            alt="Lipaworld"
            className="avatar"
          /> */}
          <span className="author-name">Lipaworld</span>
        </div>
        <Link to='/blog/support-loved-ones-in-zimbabwe' className="read-more-button"> <button className="read-more-button">Read more</button></Link>
      </div>
    </div>
  );
};

export default Fostering;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Related from '../../components/blog/Related';
import Footer from '../../components/Footer';

const ShopGroceries = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/blog');
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img src="/img/blog/pages/arrow-left.png" alt="Back" className="arrow-left" />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img src="/img/blog/ZimCross.png" alt="crossroads and lipaworld" className="community-image" />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Article</span>
                <img src="/img/blog/pages/Ellipse.png" alt="Dot" className="dot" />
                <span>15min read</span>
                <img src="/img/blog/pages/Ellipse.png" alt="Dot" className="dot" />
                <span>February 10, 2025</span>
              </div>

              <h1 className="title2">
              Supporting Loved Ones in Zimbabwe: Shop Groceries Seamlessly with Crossroads and Lipaworld
              </h1>

              <p className="description2">
                <br />
                In today’s interconnected world, supporting loved ones across borders has never been more critical. Whether it's ensuring your family has food on the table or making life easier with efficient services, providing for loved ones in Zimbabwe can feel challenging, but not anymore. With  <strong>Crossroads</strong> and
                <strong>Lipaworld</strong>, you can now shop for groceries effortlessly ensuring your family gets what they need when they need it. 🌍💳
                <br />
                <br />
                <strong>
                  <h3>Empowering Connections Across Borders
                  </h3>
                </strong>
                <br />
                Living abroad often means worrying about how to support your family back home. From ensuring the pantry is stocked to sending essentials, these responsibilities can weigh heavily. But with Lipaworld, you can:
                <br />
                ✅ Shop groceries remotely with Crossroads

                <br />
                ✅ Ensure quality essentials are delivered

                <br />
                ✅ Enjoy fast, secure, and seamless transactions
                <br />
                <br />
                <strong>
                  <h3>Shop Groceries with Crossroads via Lipaworld</h3>
                </strong>
                <br />
                Food security is one of the most vital forms of support you can offer your loved ones. Through Crossroads, you can shop for groceries and other essentials online and ensure that they’re delivered directly to your family in Zimbabwe.
                <br />
                <strong>
                  <h5>Benefits of Shopping with Crossroads via Lipaworld</h5>
                </strong>
                <br />
                <ul>
                  <li>
                    <strong>🛒 Wide Variety of Products:</strong> From staples like mealie meals and cooking oil to fresh fruits and vegetables, Crossroads offers a variety of high-quality items.
                  </li>
                  <li>
                     <strong>🚚 Convenient Delivery:</strong>  Goods are delivered straight to your loved ones’ doorstep, saving them time and effort.
                  </li>
                  <li>
                    <strong>💰 Transparent Pricing:</strong> No hidden fees—what you see is what you pay.
                  </li>
                  <li>
                    <strong>🔒 Secure Transactions:</strong> Payments are processed through Lipaworld’s secure platform, ensuring your money is safe.
                  </li>
                </ul>
                <br />
                <br />
                <strong>
                  <h3>How to Shop for Groceries with Lipaworld
                  </h3>
                </strong>
                Supporting your family with groceries is as simple as a few clicks. Here’s how you can do it:
                <br />
                <ol>
                  <li>
                    <strong>Log in to Your Lipaworld Account via the Lipaworld marketplace
                    </strong>
                  </li>
                  <li>
                     <strong>Select country Zimbabwe marketplace 
                     </strong> 
                  </li>
                  <li>
                    <strong>Navigate to the “Shop Groceries” Section:</strong> Select Crossroads from the available options.
                  </li>
                  <li>
                    <strong>Choose Your Groceries:</strong> Add essential grocery items to your cart.
                  </li>
                  <li>
                    <strong>Checkout and Pay:</strong> Complete the payment securely using your preferred method—mobile money, debit/credit card, or other payment methods.
                  </li>
                </ol>
                <br />
                Once your transaction is complete, your loved ones in Zimbabwe can expect their delivery promptly.
                <br />
                <br />
                <strong>
                  <h3>Why Choose Lipaworld?
                  </h3>
                </strong>
                <br />
                Lipaworld isn’t just a platform—it’s a bridge between you and your loved ones. By partnering with Crossroads, we ensure that:
                <br />
                <ul>
                  <li>
                    <strong>💡 You have access to quality groceries for your family.
                    </strong>
                  </li>
                  <li>
                     <strong>🛡️ Your payments are always secure. 
                     </strong> 
                  </li>
                  <li>
                    <strong>🌍 Distance is no barrier to providing for your loved ones.
                    </strong>
                  </li>
                </ul>
                <br />
                <strong>
                Key Benefits
                </strong>
                <br />
                💡 Convenience: Manage everything in one platform—quick, easy, and hassle-free.
                <br />
                💡 Speed: Instant processing ensures your loved ones receive what they need on time.
                <br />
                💡 Trust: With a secure payment platform, your transactions are safe.
                <br />
                💡 Transparency: No hidden fees, so you can trust what you’re paying for.
                <br />
                <strong>
                  <h3>A Simple Way to Make a Big Impact</h3>
                </strong>
                <br />
                Supporting your family is more than just a financial transaction—it’s about ensuring their well-being and showing that you care. With Crossroads and Lipaworld, you can make a meaningful difference in their lives, even from afar.
                <br />
                <br />
                📲 Visit us at  <a href="https://lipaworld.com" className="underline"> www.lipaworld.com </a>
                <br />
                <br />
              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default ShopGroceries;

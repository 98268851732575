import React from 'react';
import styles from './CircleAlliance.module.css';

const CircleAlliance = () => {
  return (
    <div className={styles.virtualCardContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.cardSection} data-aos="fade-right">
          <div className={styles.cardWrapper} style={{ height: '500px', overflow: 'hidden' }}>
            <iframe 
              src='https://my.spline.design/untitled-80c75abf81a88bb42773ffcdbb4ad8cf/' 
              width='100%' 
              height='125%' 
              style={{ marginTop: '-50px' }}
            />
          </div>
          <div className={styles.center}>
            <small><strong>Partnered with</strong></small>
            <img src='/img/stablecoin/circleA.svg' alt="Circle Alliance"/>
          </div>
        </div>

        <div className={styles.contentSection} data-aos="fade-left">
          <h2 className={styles.title}>
            Why We Use USDC on Lipaworld
          </h2>

          <p className={styles.subtitle}>
            As a <span className={styles.green}>Circle Alliance Member</span>, Lipaworld ensures secure, fast, and low-cost money transfers using USDC (USD Coin) – a trusted, fully-backed stablecoin.
          </p>

          <p className={styles.description}>
            Benefits for You:
          </p>

          <ul className={styles.featuresList}>
            <li className={styles.featureItem}>
              <span className={styles.featureTitle}>Security & Trust:</span>
              <span className={styles.featureText}>
                USDC is fully backed and redeemable 1:1 for USD.
              </span>
            </li>
            <li className={styles.featureItem}>
              <strong className={styles.featureTitle}>Fast & Low-Cost Transfers:</strong>
              <span className={styles.featureText}>
                Enjoy instant settlements with reduced fees.
              </span>
            </li>
            <li className={styles.featureItem}>
              <span className={styles.featureTitle}>Stability & Transparency:</span>
              <span className={styles.featureText}>
                A regulated digital dollar with no price volatility.
              </span>
            </li>
            <li className={styles.featureItem}>
              <span className={styles.featureTitle}>Exclusive Benefits:</span>
              <span className={styles.featureText}>
                As a Circle Alliance Member, we offer better rates and liquidity.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CircleAlliance;
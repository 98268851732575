import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Related from '../../components/blog/Related';
import Footer from '../../components/Footer';

const PNP = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/blog');
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img src="/img/blog/pages/arrow-left.png" alt="Back" className="arrow-left" />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img src="/img/blog/pnp.jpg" alt="crossroads and lipaworld" className="community-image" />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Press Release</span>
                <img src="/img/blog/pages/Ellipse.png" alt="Dot" className="dot" />
                <span>13min read</span>
                <img src="/img/blog/pages/Ellipse.png" alt="Dot" className="dot" />
                <span>February 3, 2025</span>
              </div>

              <h1 className="title2">
                Lipaworld Joins the Inclusive Fintech Accelerator Program(Plug and Play) Cohort 2
              </h1>

              <p className="description2">
                <br />
                We are thrilled to announce that <strong>Lipaworld</strong> has been selected to join the{' '}
                <strong>Inclusive Fintech Accelerator Program Cohort 2</strong>, an initiative dedicated to fostering
                innovation and inclusion in the fintech industry. This marks a significant milestone in our journey as
                we continue to build solutions that drive financial accessibility and technological advancements in the
                sector.
                <br />
                <br />
                <strong>
                  <h3>About the Inclusive Fintech Accelerator Program</h3>
                </strong>
                <br />
                The Inclusive Fintech Accelerator is a prestigious program designed to support fintech startups by
                providing key resources, mentorship, and unparalleled networking opportunities. Over the next six
                months, we will collaborate with industry leaders, investors, and fellow startups to refine our
                offerings, expand our market reach, and create an even greater impact in the financial technology
                ecosystem.
                <br />
                <br />
                <strong>
                  <h3>Why This Matters for Lipaworld</h3>
                </strong>
                <br />
                Joining this accelerator means that we will:
                <br />
                <ul>
                  <li>
                    Gain access to <strong>expert mentorship</strong> from industry professionals and successful
                    entrepreneurs.
                  </li>
                  <li>
                    Connect with <strong>potential investors and corporate partners</strong> who share our vision of
                    fintech innovation.
                  </li>
                  <li>
                    Participate in <strong>exclusive workshops and training sessions</strong> tailored to our growth and
                    scalability.
                  </li>
                  <li>
                    Leverage <strong>Plug and Play’s vast global network</strong>, helping us expand our reach across
                    multiple regions.
                  </li>
                </ul>
                <br />
                <br />
                <strong>
                  <h3>About Plug and Play</h3>
                </strong>
                <br />
                Plug and Play is the world’s leading <strong>innovation platform</strong>, connecting startups,
                corporations, venture capital firms, universities, and government agencies. Headquartered in{' '}
                <strong>Silicon Valley</strong>, Plug and Play operates in{' '}
                <strong>60+ locations across 5 continents</strong>. With a strong track record of success, Plug and Play
                has invested in hundreds of groundbreaking companies, including Dropbox, Guardant Health, Honey, Lending
                Club, N26, PayPal, and Rappi. The organization is committed to driving innovation across multiple
                industries, including fintech, ensuring that startups like ours have the support and resources they need
                to thrive.
                <br />
                <br />
                <strong>
                  <h3>Join Us on This Journey</h3>
                </strong>
                <br />
                As we embark on this transformative experience, we invite you to follow our progress and stay engaged
                with our updates. We’ll be sharing insights, key learnings, and major milestones along the way.
                <br />
                <br />
                <strong>
                  📲 Follow us on social media: @LipaworldAfrica on LinkedIn | Twitter | Instagram | Facebook
                </strong>
                <br />
                <br />
                <strong>
                  <h5>📢 Join the conversation using: #PNPTC</h5>
                </strong>
                <br />
                We extend our gratitude to Plug and Play for this incredible opportunity, and we look forward to the
                exciting months ahead!
                <br />
                <br />
                Stay tuned for more updates from @LipaworldAfrica as we take fintech innovation to the next level!
                <br />
                <br />
              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default PNP;
